@value max-small-tablet, min-small-tablet, min-small-desktop from "../styles/css-values/breakpoints.module.css";

.sentientBackground {
    color: var(--color-on-background);
    background-color: var(--color-background);
    height: 100vh;
    overflow: auto;
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  
  }

  .scrollable {
    /* 
    fixes repaints on scroll but can have potentially bad impact on text rendering 
    https://stackoverflow.com/questions/24741502/can-i-do-anything-about-repaints-on-scroll-warning-in-chrome-for-overflowscr
    */
    will-change: transform;
  
    flex-grow: 1;
    height: 100%;
    display: flex;
    overflow-y: auto;
    /* grid-template-columns: 1fr auto; */
  }
  
  .sentientBackground::-webkit-scrollbar {
    /* hides the extra scrollbar for chrome
    */
    display: none;
  }

.explorer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0fr 1fr;
  width: 98vw;
}

.img {
  height: 42px;
  margin-top: 0.2rem;
  cursor: pointer;
}

@media max-small-tablet {
  .img {
    display: none;
  }
}
