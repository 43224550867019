.container {
  padding: 2.4rem;
  padding-left: calc(1.6rem + 2.4rem + 1.6rem);
  border-radius: 1.2rem;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
}

.icon {
  top: 2.4rem;
  left: 1.6rem;
  height: 2.4rem;
  position: absolute;
  border-radius: 10px;
}

.currencyName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
}

.rate {
  composes: type-body-medium from global;
  margin-top: 0.8rem;
}

.rate :not(strong) {
  opacity: 0.9;
}

.actions {
  margin-top: 2.4rem;
  display: flex;
  gap: 0.8rem;
  justify-content: space-between;
  align-self: flex-end;
  margin-left: -2.4rem;
  margin-right: -0.8rem;
}
