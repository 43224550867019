.container {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin-top: 2.4rem;
  gap: 0.8rem;
}

.actions {
  display: flex;
  gap: 0.8rem;
  justify-content: flex-end;
  margin-top: 1.6rem;
}

.exchangeRecord {
  position: unset;
  display: flex;
  flex-direction: column;
}
