.error-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e2431;
  color: white;
}

.error-page {
  width: 50%;
  height: 70%;
  background-color: #333a4a;
  box-shadow: 0 0 5px 5px #e56907;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: default;
}

.error-num {
  font-size: 10rem;
  font-weight: 100;
}

.error-text {
  font-size: 3rem;
  margin-bottom: 40px;
}

.error-button {
  height: 50px;
  width: 150px;
  background-color: #e56907;
  color: white;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  border-radius: 10px;
}

.error-button:hover {
  background-color: #b86e34;
}
