@value max-small-tablet, min-small-tablet, min-small-desktop from "../../styles/css-values/breakpoints.module.css";

.sentientBackground {
    color: var(--color-on-background);
    background-color: var(--color-background);
}

.container {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.scrollable {
    /* 
    fixes repaints on scroll but can have potentially bad impact on text rendering 
    https://stackoverflow.com/questions/24741502/can-i-do-anything-about-repaints-on-scroll-warning-in-chrome-for-overflowscr
    */
    will-change: transform;

    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* grid-template-columns: 1fr auto; */
}
  
.main {
    padding: 0 1.6rem;
    flex-grow: 1;
}

.pageBody {
    display: grid;
    grid-template-columns: 1fr;
}

.marketsHeadline {
    font-size: 600;
    font-weight: 500;
    margin-bottom: 3.2rem;
}

.markets {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    flex-wrap: wrap;
    gap: 1rem;
}

@media max-small-tablet {
    .container {
        height: -webkit-fill-available;
    }
    .main {
        padding: 0;
        flex-grow: 1;
    }
    .marketsContainer {
        padding: 0.8rem;
    }
}
@media min-small-tablet {
    .container {
      flex-direction: row;
      margin-bottom: 0rem;
}
}