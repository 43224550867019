.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.8rem;
  padding: 3.2rem 0;
  min-height: 800px;
}

.lastInvoice {
  composes: type-body-medium from global;
  margin-bottom: 0.8rem;
}

.accordions {
  margin-top: 1.6rem;
}

.shareAccordion {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shareFields {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 1.6rem;
}

.amount {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: flex-start;
  gap: 0.8rem;
  border: 0;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
}

.requestFields {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
}

.createLink {
  align-self: end;
  flex-grow: 0;
}

.dialogDescription {
  display: flex;
  flex-direction: column;
}

.dialogDescription > * + * {
  margin-top: 1.6rem;
}

.depositButton > * {
  width: 100%;
}