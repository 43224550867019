@import "./styles/css/dark-theme.css";
@import "./styles/css/light-theme.css";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
}

/* @media screen and (max-width: 500px) {
  html {
    font-size: 55%;
  }
} */
