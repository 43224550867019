@value max-small-tablet, min-small-tablet, min-small-desktop, phone from "../styles/css-values/breakpoints.module.css";

.sentientBackground {
  color: var(--color-on-background);
  background-color: var(--color-background);
}

.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.navBar {
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: grid;
  flex-grow: 0;
  order: 1;
  height: 5rem;
  width: 100%;
  justify-content: stretch;
  align-items: center;
  background-color: var(--color-surface);
}

.scrollable {
  /* 
  fixes repaints on scroll but can have potentially bad impact on text rendering 
  https://stackoverflow.com/questions/24741502/can-i-do-anything-about-repaints-on-scroll-warning-in-chrome-for-overflowscr
  */
  will-change: transform;

  flex-grow: 1;
  height: 100%;
  display: flex;
  overflow-y: auto;
  -ms-overflow-style: auto;  /* IE and Edge */
  scrollbar-width: auto;  /* Firefox */
  /* grid-template-columns: 1fr auto; */
}

::-webkit-scrollbar {
  width: 7px;
  background: #000;
}
::-webkit-scrollbar-thumb {
  background: lightgray;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}

.scrollable::-webkit-scrollbar {
  /* hides the extra scrollbar for chrome
  */
  display: flex;
}

.main {
  padding: 0 1.6rem;
  flex-grow: 1;
}

.navBar img {
  display: none;
}

.navBar:nth-child(4) {
  display: none;
}

@media max-small-tablet {
  .container {
    position: relative;
    height: -webkit-fill-available;
  }
  .main {
    padding: 0rem;
    flex-grow: 1;
  }
}
@media min-small-tablet {
  .container {
    flex-direction: row;
  }

  .navBar {
    height: 100vh;
    position: relative;
    margin-left: 1.6rem;
    margin-right: 2.4rem;
    order: 0;
    width: auto;
    background-color: transparent;
    padding-top: 5rem;
  }

  .navBar img {
    display: flex;
    height: 42px;
    margin-left: 0.7rem;
    margin-top: 0.2rem;
    position: absolute;
    place-self: center;
    top:0;
    cursor: pointer;
  }

  .navBar:nth-child(4th) {
    display: flex;
  }

  .main {
    padding: 0 2.4rem 0 0;
  }
}

.sideBar {
  display: none;
  flex-grow: 0;
  flex-basis: 0;
  position: sticky;
  top: 0;
  border-radius: 3.2rem 0 0 3.2rem;
}

@media min-small-desktop {
  .sideBar {
    display: block;
  }
  .navBar {
    position: relative;
    height: 100vh;
  }
}

@media phone {
  .scrollable {
    overflow-x: hidden;
  }
}