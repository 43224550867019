.container{
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.explorerContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
}

.section {
    display: flex;
    align-items: stretch;
    gap: 1.6rem;
    flex-direction: column;
    border-radius: 10px;
    word-break: break-all;
}

.sectionPending {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
    border-radius: 10px;
    padding: 0.8rem;
    word-break: break-all;
}

.sectionPendingAdditional {
    display: grid;
    align-items: center;
    gap: 1.6rem;
    border-radius: 10px;
    padding: 2rem;
}

.sectionPendingContent {
    font-weight: 700;
}

.operations {
    display: grid;
}

.sectionSenders{
    display: grid;
    align-items: flex-start;
    gap: 1.6rem;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
}

.sectionAdditional {
    display: grid;
    align-items: flex-start;
    gap: 1.6rem;
    grid-template-columns: 1fr;
    border-radius: 10px;
    margin-bottom: 2rem;
    word-break: break-all;
}

.headline {
    display: flex;
    gap: 2rem;
    align-items: center;
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    margin-top: 1.4rem;
    margin-bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    grid-row: 1;
    grid-column: var(--grid-column);
    position: sticky;
    left: 0;
}

.headlineAdditional {
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 1.4rem;
    margin-bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    grid-row: 1;
    grid-column: var(--grid-column);
    position: sticky;
    left: 0;
}

.headlineSenders {
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    margin-top: 1.4rem;
    margin-bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    grid-row: 1;
    grid-column: var(--grid-column);
    position: sticky;
    left: 0;
    padding-bottom: 1rem;
    cursor: pointer;
}

.headlineContent {
    display: flex;
    flex-direction: column;
}

.headlineText {
    font-size: 1.5rem;
}

.headlineNumber{
    font-size: 1.6rem;
    font-weight: 600;
}

.number {
    border-radius: 10px;
    background-color: var(--color-surface);
    background-size: 1.6rem 1.6rem;
}

.headline:focus-within > {
    background-color: orange;
}

.headline:focus-within > .number {
    background-color: orange;
}

.infoDropdown {
    display: flex;
    cursor: pointer;
}

.dropdownIcon {
    margin-top: 0.4rem;
}

.number:focus-within {
    background-color: orange;
}

.copyLogo {
    border-radius: 0px 10px 0px 10px;
    color: var(--color-on-surface);
}
  
.copyLogo:hover {
    cursor: pointer;
    color: var(--color-logo);
}
  
.copyLogo:active {
    box-shadow: 0px 0px 3px var(--color-logo);
    transform: translateY(1px) ease;
}

.confirmationsHeader {
    display: flex;
    gap: 4rem;
    vertical-align: middle;
}

.checkIcon {
    margin-left: 1.9rem;
    margin-top: 1.9rem;
    color: white;
}
.pie {
    height: 7rem;
    min-width: 7rem;
    border-radius: 50%;
    
}
.pie0 {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    /* background: conic-gradient(#fe6b00 -200deg, 
                                20grad, var(--color-surface-tint-at-0) 720deg); */
    /* background: linear-gradient(#fe6b00 0%, 2%, var(--color-surface-tint-at-2) 45%, 90%, #fe6b00 99%); */
}
.pie1 {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    /* background: conic-gradient(var(--color-logo) 20deg,var(--color-logo-inverse) 72deg, 
                                85grad, var(--color-surface-tint-at-0)); */
}
.pie2 {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    /* background: conic-gradient(var(--color-logo) 72deg,var(--color-logo-inverse) 144deg, 
                                172grad, var(--color-surface-tint-at-0)); */
}
.pie3 {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    /* background: conic-gradient(var(--color-logo) 108deg,var(--color-logo-inverse) 216deg, 
                                242grad, var(--color-surface-tint-at-0)); */
}
.pie4 {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    /* background: conic-gradient(var(--color-logo) 144deg,var(--color-logo-inverse) 288deg, 
                                330grad, var(--color-surface-tint-at-0)); */
}
.dots {
    display: flex;
    gap: 0.6rem;
    margin-top: 2.35rem;
    margin-left: 1.95rem;
}
.dotOne {
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 50%;
    animation: left-to-right 1.4s ease-in-out 1s infinite;
}
.dotTwo {
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 50%;
    animation: left-to-right 1.4s ease-in-out 1.3s infinite;
}
.dotThree {
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 50%;
    animation: left-to-right 1.4s ease-in-out 1.6s infinite;
}

.transactionSuccessful {
    margin-top: 1.6rem;
}

.errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--md-ref-palette-error-50);
    gap: 2rem;
}

.errorImage {
    height: 150px;
    width: 150px;
}

@keyframes left-to-right {
    50% {
        transform: translateY(200%);
    }
}

@media screen and (max-width: 996px) {
    .explorerContainer {
        grid-template-columns: 1fr;
    }
    .section {
        display: flex;
        flex-wrap: wrap;
        word-break: break-all;
    }
    .sectionSenders {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        word-break: break-all;
        gap: 0rem;
    }
    .sectionAdditional {
        display: flex;
        flex-wrap: wrap;
        word-break: break-all;
    }
    .container {
        margin-top: 2rem;
    }
    .transactionSuccessful {
        font-size: 1.8rem;
    }
    .errorContainer {
        margin-top: 2rem;
    }
}
