.operationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.4rem;
    gap: 0.8rem;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    border-radius: 10px;
    width: 100%;
    flex-grow: 1;
    color: var(--primary);
}

.detail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.8rem;
    align-items: start;
}

.operationItem {
    display: flex;
    height: 100%;
    border-radius: 5px;
    padding: 0.4rem;
    flex-direction: column;
    min-height: 50px;
    word-break: break-all;
    justify-content: center;
}

.operationItemAmount {
    display: flex;
    height: 100%;
    border-radius: 5px;
    padding: 0.4rem;
    align-items: center;
    min-height: 50px;
    word-break: normal;
}

.operationItemAmountAddress {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    padding: 0.4rem;
    min-height: 50px;
    word-break: normal;
    justify-content: center;
}

.operationItemType {
    display: flex;
    height: 100%;
    border-radius: 5px;
    padding: 0.4rem;
    align-items: center;
    min-height: 50px;
    word-break: normal;
}

.operationItemDate {
    display: flex;
    height: 100%;
    border-radius: 5px;
    padding: 0.4rem;
    align-items: center;
    min-height: 50px;
    word-break: normal;
}

.address {
    display: flex;
    justify-content: space-between;
}

.descriptionInfoItem {
    overflow: hidden;
    text-overflow: ellipsis;
}

.number {
    font-weight: 600;
}

.descriptionHighlight {
    color: var(--color-logo);
    font-weight: 500;
    overflow: hidden;
    display: flex;
    gap: 1rem;
}

.descAddress {
}

@value breakpoints: "../../styles/css-values/breakpoints.module.css";
@value max-large-tablet from breakpoints;
@value max-laptop from breakpoints;


@media max-laptop {
    .detail {
        display: grid;
        grid-template-columns: 1fr 1fr;
    } 
}

@media max-large-tablet {
    .detail {
        display: grid;
        grid-template-columns: 1fr;
    } 
}