.container {
  composes: type-body-medium from global;
  padding: 2.4rem;
  background-color: var(--color-disabled-container);
  color: var(--color-disabled-content);
  border-radius: 0.8rem;
}

.container svg {
  margin-right: 0.8rem;
}
