@value max-small-tablet from "../../styles/css-values/breakpoints.module.css";

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;
  padding: 2.4rem 0;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.amount {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 0.8rem;
  border: 0;
}

.breathingRoom {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.6rem;
}

.advanced {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-end;
  gap: 0.8rem;
}

.advancedTemplate {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: flex-end;
  justify-content: center;
  gap: 0.8rem;
}

.advanced [class^='NetworkPicker_singleValue'] {
  justify-self: flex-end;
}

.descriptionList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.descriptionTerm {
  font-size: 1.7rem;
}

.descriptionDetailsContainer {
  background-color: var(--color-surface);
  color: var(--color-on-surface);
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
}

.value {
  height: 1.5em;
}

.convertedValue {
  composes: type-body-small from global;
  opacity: 0.8;
}

.actions {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 2.4rem;
}

.comboboxItems{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 988px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }

  .descriptionList {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  
  .descriptionTerm {
    display: inline-block;
    flex-grow: 0;
    margin-right: 2.4rem;
  }
  
  .descriptionDetailsContainer {
    /* box-shadow: var(--shadow-1); */
    background-color: var(--color-surface);
    color: var(--color-on-surface);
    padding: 1.2rem 2.4rem;
    border-radius: 2.4rem;
    margin-bottom: 1.6rem;
    width: 24rem;
  }
}

@media (min-width: 725px) and (max-width: 987px){
  .amount {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1350px){
  .amount {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 987px) {
  .descriptionList {
    grid-template-columns: 1fr 1fr;
  }
  
}