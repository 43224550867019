.container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    width: 100%;
    flex-grow: 1;
    color: var(--primary);
    word-break: normal;
}

.address {
    font-weight: 600;
}

.descriptionInfoItem {
    overflow: hidden;
    text-overflow: ellipsis;
}

.number {
    font-weight: 600;
}

.descriptionHighlight {
    color: var(--color-logo);
    font-weight: 500;
    overflow: hidden;
    display: flex;
    gap: 1rem;
}

.copyLogo {
    border-radius: 0px 10px 0px 10px;
    color: var(--color-on-surface)
}
  
.copyLogo:hover {
    cursor: pointer;
    color: var(--color-logo);
}

.copyLogo:active {
    box-shadow: 0px 0px 3px var(--color-logo);
    transform: translateY(1px) ease;
}

.descAddress {
}
  
@media screen and (max-width: 996px) {
}
  