.api-key-wrapper {
  position: relative;
}

.api-whitelist-wrapper {
  width: 100%;
  max-width: 1224px;
  color: var(--color-on-surface);
  display: grid;
  grid-template-columns: 1fr;
  font-family: 'Inter', sans-serif;
  padding: 1rem;
  gap: 12.2rem;
  border-radius: 10px;
  justify-content: space-between;
  /* justify-content: space-around; */
}

.container {
  padding-bottom: 8rem;
}

.api-whitelist-wrapper h1 {
  font-size: 2rem;
  padding: 0 1rem;
}

.api-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  margin-right: 50px;
}

.api-list {
  display: flex;
  flex-direction: column;
}

.api-not-found {
  padding-left: 1rem;
}

.api {
  display: block;
  width: 100%;
  padding: 1.6rem;
  margin: 1rem 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.key-info {
  display: flex;
  flex-direction: column;
}

.key-date {
  font-size: 1.1rem;
  font-weight: 300;
}

.add-new-api {
  margin-left: 0.5rem;
  width: 22rem;
} 

.new-api-key {
  position: absolute;
  top: 0%;
  left: 0%;
  /* -ms-transform: translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%); */
  /* transform: translate(0%,-200%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-on-surface);
  padding: 2rem;
  margin: 1rem;
  border-radius: 5px;
  border: 1px solid var(--color-logo);
  z-index: 100;
}

.new-api-key h3,
.new-api-key span {
  
}

.new-api-key span {
  word-break: break-all;
}

.new-api-key h3 {
  color: var(--color-logo);
  margin-bottom: 1rem;
}

.new-key-close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 2rem;
  cursor: pointer;
}

.tfa-check {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30rem;
  gap: 0.8rem;
  padding: 1rem;
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid var(--color-logo);
  border-radius: 10px;
}
.tfa-check > span {
  margin-top: 2.5rem;
  &.error {
    margin-top: 0;
  }
}
.tfa-check-close {
  position: absolute;
  display: flex;
  width: 2rem;
  height: 3rem;
  right: 0;
}

.enable-tfa-message-api {
  display: flex;
  align-items: flex-end;
  color: var(--md-ref-palette-error-50);
}

@media screen and (max-width: 996px) and (min-width: 811px) {
  .api-whitelist-wrapper {
    width: 80vw;
  }
}

@media screen and (max-width: 810px) {
  .api-whitelist-wrapper {
    flex-direction: column;
    width: 75vw;
    gap: 2rem;
  }

  .api-list-wrapper {
    margin-left: 1rem;
  }

  .add-new-api {
    margin-left: 0.1rem;
  }
}

@media screen and (max-width: 599px) {
  .api-whitelist-wrapper {
    width: 91vw;
  }
}