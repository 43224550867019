.container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 1.6rem;
}

.paymentInfoContainer {
    display: grid;
    padding: 2.4rem;
    border-radius: 10px;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
        'field  field   entries  entries'
        'field  field   preview  preview'
        '  .    submit  submit      .   ';
    gap: 1.6rem;
}

.currencyField {
    display: flex;
    align-items: flex-end;
    gap: 0.8rem;
    grid-area: field;
}

.currencyEntries {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.6rem;
    grid-area: entries;
}

.currencyEntry {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.8rem;
    border-radius: 10px;
}

.paymentPreview {
    display: flex;
    gap: 1.6rem;
    grid-area: preview;
}

.submitForm {
    display: flex;
    justify-self: center;
    gap: 1.6rem;
    grid-area: submit;
}

@value breakpoints: "../../styles/css-values/breakpoints.module.css";
@value max-large-tablet from breakpoints;
@value max-laptop from breakpoints;

@media max-laptop{
    .paymentInfoContainer {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
            'field    field'
            'entries  entries'
            'preview  preview'
            'reset    submit';
    }
    .paymentPreview {
        flex-direction: column;
    }
}