.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 2.4rem;
  gap: 0.8rem;
}

.amount {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 0.8rem;
  border: 0;
}

.breathingRoom {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.6rem;
}

.advanced {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-end;
  gap: 0.8rem;
}

.advanced [class^='NetworkPicker_singleValue'] {
  justify-self: flex-end;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  margin-top: 0.8rem;
}

.comboboxItems{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.descriptionList {
  composes: type-body-medium from global;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0 1.6rem;
}

.descriptionList dt {
  justify-self: flex-end;
  opacity: 0.7;
  grid-column: 1 / 2;
}

.descriptionList dd {
  justify-self: flex-start;
  grid-column: 2 / 3;
}