.container {
  composes: type-body-medium from global;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0 1.6rem;
}

.container dt {
  justify-self: flex-end;
  opacity: 0.7;
  grid-column: 1 / 2;
}

.container span {
  justify-self: flex-end;
  opacity: 1;
  grid-column: 1 / 2;
}

.container dd {
  justify-self: flex-start;
  grid-column: 2 / 3;
}

