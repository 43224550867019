.section {
    display: grid;
    width: auto;
    justify-content: center;
    border-radius: 10px;
    padding: 1.6rem;
}

.sectionSmall {
    display: grid;
    width: 100%;
    border-radius: 10px;
    padding: 0.8rem;
}

.header {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
}

.headerSmall {
    margin-left: 1.6rem;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
}

.headLine {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 0.8rem;
    font-size: 1.8rem;
}

.headLineSmall {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    gap: 2rem;
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    margin-left: 1.6rem;
    align-items: center;
}

.body {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}

.resolveButton > * {
    width: 100%;
}

.details {
    display: grid;
    padding: 1.6rem;
    font-size: 1.4rem;
    border-radius: 10px;
    gap: 1.6rem;
}

.detailsContent {
    font-weight: 800;
}

.closeButton {
    display: flex;
    justify-content: flex-end;
}

@value breakpoints: "styles/css-values/breakpoints.module.css";
@value max-large-tablet from breakpoints;

@media max-large-tablet {
    .headLine {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}