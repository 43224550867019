.container{
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
}


