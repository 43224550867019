.operationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.4rem;
    gap: 0.4rem;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    border-radius: 10px;
    width: 100%;
    flex-grow: 1;
    color: var(--primary);
}

.headerContainer {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    margin-top: 2.4rem;
    border-radius: 10px;
    width: 100%;
    flex-grow: 1;
    color: var(--primary);
}

.detailsHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.8rem;
    align-items: start;
}

.details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.8rem;
    align-items: start;
}

.detailsHeader > h3 {
    font-size: 1.6rem;
}

.detail {
    display: flex;
    height: 100%;
    border-radius: 5px;
    padding: 0.4rem;
    word-break: break-all;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
}

.paymentButton {
    height: 3.2rem;
}

.mobileHeader {
    display: none;
}

@value breakpoints: "../../styles/css-values/breakpoints.module.css";
@value max-large-tablet from breakpoints;
@value max-laptop from breakpoints;


@media max-laptop {
    .details {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
    } 

    .container {
        margin-top: 2.4rem;
    }

    .headerContainer {
        display: none;
    }

    .detail {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
    }
    .mobileHeader {
        display: flex;
    }
}

@media max-large-tablet {
    .details {
        display: grid;
        grid-template-columns: 1fr;
    } 

    .container {
        margin-top: 2.4rem;
    }
    
    .headerContainer {
        display: none;
    }

    .mobileHeader {
        display: flex;
    }
}