.surface {
  background-image: radial-gradient(
    farthest-corner at bottom left,
    var(--container-color),
    var(--container-color) 20%,
    transparent
  );
  /* backdrop-filter: blur(1px); */
  color: var(--content-color);
}
