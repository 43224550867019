.securityPage {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 2rem;
  font-family: 'Inter', sans-serif;
  color: var(--color-on-surface);
  display: table;
  flex-direction: column;
  gap: 2rem;
}

.pageHeadline {
  composes: type-headline-medium from global;
  margin-bottom: 0.8rem;
}

.accordion {
  height: auto;
  max-width: 80rem;
  
}

.accordion2FA {
  display: flex;
}

.sec__title {
  font-size: 2.2rem;
  font-weight: 400;
}

.sec_error {
  margin: 20px auto;
  text-align: center;
  border-radius: 5px;
  width: 300px;
  background-color: red;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--txt-main);
}

.sec__form {
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border: 0.5px solid black;
  background-color: var(--srf-main);
}

.sec__form input {
  width: 196px;
}

.security img {
  margin-bottom: 15px;
}

.security input {
  margin-bottom: 15px;
  height: 30px;
  padding: 0 5px;
  border-radius: 3px;
  border: 0.5px solid black;
}

.sec__button {
  padding: 5px 15px;
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.btn-danger {
  background-color: red;
}

.btn-success {
  background-color: green;
}

.btn-generate {
  background-color: #e56907;
}

.tfa-enabled {
  display: flex;
}

.tfa-section {
  /* width: 60%; */
  display: flex;
  margin-bottom: 2.5rem;
}

.tfa-main {
  width: 350px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.tfa-info {
  width: 40rem;
  height: 130px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.tfa-info > span {
  padding-top: 7px;
}

.ip-whitelist-section,
.api-key-section {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--txt-main);
  padding: 1rem;
}

.ip-whitelist-section h1,
.api-key-section h1 {
  width: 350px;
}

.ip-whitelist-action {
  width: 40rem;
}

@media screen and (max-width: 810px) {
  .tfa-section {
    width: 400px;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .tfa-main {
    width: 85%;
  }

  .tfa-info {
    width: 80%;
    justify-content: space-evenly;
  }

  .ip-whitelist-section,
  .api-key-section {
    width: 400px;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
  .botMargin {
    padding-bottom: 5rem;
  }
}
