@value max-small-tablet, min-small-tablet from "../../styles/css-values/breakpoints.module.css";

/* container styles with orientation */
.nav:global(.--horizontalOrientation) .list,
.nav:global(.--autoOrientation) .list {
  display: flex;
  flex-direction: row;
}

.nav:global(.--verticalOrientation) .list {
  display: inline-flex;
  flex-direction: column;
}
@media min-small-tablet {
  .nav:global(.--autoOrientation) .list {
    display: inline-flex;
    flex-direction: column;
  }
}

/* navBarItem styles with Orientation */
.nav:global(.--horizontalOrientation) .navBarItem,
.nav:global(.--autoOrientation) .navBarItem {
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
  height: 8rem;
}

@media max-small-tablet {
  .nav:global(.--horizontalOrientation) .navBarItem,
  .nav:global(.--autoOrientation) .navBarItem {
    height: 4rem;
  }
}

.nav:global(.--verticalOrientation) .navBarItem {
  flex-direction: column;
  height: 8rem;
  width: 8rem;
}
@media min-small-tablet {
  .nav:global(.--autoOrientation) .navBarItem {
    flex-direction: column;
    height: 8rem;
    width: 8rem;
    flex: unset;
  }
}

/* basic */
.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* outline: 1px solid red; */
}

.navBarItem {
  composes: type-label-medium from global;
  margin: 0;
  padding: 0;
}

.navBarLink {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.activeIndicator {
  color: var(--color-logo-inverse);
  position: absolute;
  width: 9rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* align-self: center; */
  /* justify-self: center; */
  z-index: -1;
  opacity: 0;
  transition: opacity 0.15s;
}

@media max-small-tablet {
  .activeIndicator {
    width: 6.5rem;
  }
}

.navBarItemIcon {
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* box-shadow: 0 0 0 1px red; */
  transition: transform 0.25s ease-out;
}

@media max-small-tablet {
  .navBarItemIcon {
    width: 4.2rem;
    height: 4.2rem;
    align-items: end;
  }
}

.navBarItemIcon svg {
  transition: color 0.25s ease-in-out;
}

.navBarItemLabel {
  margin-bottom: 1.2rem;
}

@media max-small-tablet {
  .navBarItemLabel {
    margin-bottom: 0rem;
    font-size: 1.1rem;
  }
}

.navBarLink:hover {
  opacity: 1;
}

.navBarLink:focus {
  outline: none;
}

.navBarLink:global(.focusRing)::after {
  content: '';
  position: absolute;
  background-color: var(--color-on-surface);
}

/* active state */
.navBarLink:global(.--isActive) {
  opacity: 1;
  color: var(--color-on-background);
}

.navBarLink:global(.--isActive) .navBarItemIcon {
  transform: translateY(-0.2rem);
}

.navBarLink:global(.--isActive) .activeIndicator {
  opacity: 1;
}
