.container {
  margin-bottom: 64px;
}

.container :global(.h) {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.container p {
  max-width: 60ch;
  margin-bottom: 1em;
}

.listBoxes {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.listBoxContainer {
  width: 280px;
  flex-grow: 0;
}
