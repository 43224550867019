.navBars {
  display: flex;
  gap: 24px;
}

.navBar {
  width: 480px;
}

.container p {
  max-width: 60ch;
}
