@value max-small-tablet from "../../styles/css-values/breakpoints.module.css";

.tabs:global(.--inline) {
  display: flex;
  gap: 0.8rem;
}

@media max-small-tablet {
  .tabs:global(.--inline) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.tabs:global(.--stretch) {
  display: flex;
  justify-content: space-between;
}

.tab {
  font-family: 'Inter',sans-serif;
  font-size: 1.4rem;
  height: 4.8rem;
  padding: 0 2.4rem;
  display: flex;
  align-items: center;
  border-radius: 1.6rem;
  background-color: var(--color-disabled-container);
  color: var(--color-inverse-surface);
  cursor: pointer;
  position: relative;
  will-change: border-radius, background-color, color;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
    border-radius 0.35s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tab:focus {
  outline: none;
}

.tab:not(:global(.--isDisabled)):hover::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-on-secondary-container);
  opacity: var(--interaction-layer-hovered);
  border-radius: inherit;
}

.tab:global(.--isSelected) {
  border-radius: 2.4rem;
  background-color: var(--color-logo);
  color: var(--color-on-secondary);
}

.tab:global(.--isDisabled) {
  background-color: var(--color-disabled-container);
  color: var(--color-disabled-content);
}

.tab:global(.--focusRing)::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  top: 0;
  left: 0;
  width: calc(100% + 0.6rem);
  height: calc(100% + 0.6rem);
  transform: translate(-0.3rem, -0.3rem);
  border-radius: calc(4.8rem / 2 + 0.3rem);
  box-shadow: 0 0 0 2px var(--color-on-surface);
}
