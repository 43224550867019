.listbox {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.focusRing {
  position: relative;
}

.focusRing:global(.--isKeyboardFocused)::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;

  width: calc(100% + 0.6rem);
  height: calc(100% + 0.6rem);
  transform: translate(-0.3rem, -0.3rem);
  border-radius: 1.8rem;
  box-shadow: 0 0 0 2px var(--color-on-surface);
}

.summary {
  composes: type-label-large from global;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 1.6rem 0.8rem;
  gap: 0 1.6rem;
  background-color: var(--color-logo);
  color: var(--color-surface);
  border-radius: 1.6rem;
  transition: background-color 0.15s ease-in-out;
}

.listItem:global(.--isOpen) .summary {
  background-color: var(--color-logo-inverse);
  color: var(--color-inverse-surface);
}

.icon {
  transform: rotate(-90deg);
  transition: transform 0.15s ease-in-out;
}

.listItem:global(.--isOpen) .icon {
  transform: rotate(0);
}

.icon,
.summaryText {
  /* 
  Making line height same for the icon and text helps with positioning 
  both at the same level when long text starts to wrap to the next line.
  Font size must be defined on the first common ancestor to take into 
  account larger and smaller font size variations.
  */
  line-height: 1.4em;
}

.summaryText {
  text-align: start;
}
