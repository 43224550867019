@value max-small-tablet from "../../styles/css-values/breakpoints.module.css";

.container {
  margin-top: 0rem;
  padding-bottom: 10rem;
}

.header {
  display: flex;
}

.pageHeadline {
  composes: type-headline-medium from global;
  margin-bottom: 3.2rem;
}

.myWallets {
  padding: 2.4rem;
  border-radius: 2.4rem;
}

.balanceHeadline {
  composes: type-title-small from global;
}

@media max-small-tablet {
  .container{
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
}