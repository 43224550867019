.container {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2.4rem 1.6rem;
}

.title {
  align-self: center;
  margin-bottom: 0;
  margin-top: 0;
}

.icon {
  order: -1;
  align-self: center;
  animation: var(--animation-shake);
}

.title + .icon {
  margin-bottom: 1.6rem;
}

.description {
  composes: type-body-large from global;
  margin-top: 1.6rem;
}

.actions {
  display: flex;
  gap: 0.8rem;
  justify-content: flex-end;
  margin-top: 2.4rem;
  flex-wrap: wrap-reverse;
}
