@value min-large-phone from "../../styles/css-values/breakpoints.module.css";
@value min-small-tablet from "../../styles/css-values/breakpoints.module.css";
@value min-large-tablet from "../../styles/css-values/breakpoints.module.css";
@value min-laptop from "../../styles/css-values/breakpoints.module.css";
@value min-laptop from "../../styles/css-values/breakpoints.module.css";
@value max-large-phone @value min-laptop from "../../styles/css-values/breakpoints.module.css";

.container {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.mainHeading {
  composes: type-headline-medium from global;
  margin-bottom: 3.2rem;
}

.table {
  border-radius: 1.2rem 1.2rem 0 0;
  overflow: hidden;
}

.filterHeading {
  composes: type-title-medium from global;
  padding: 1.6rem 2.4rem 0;
  margin: 0;
}

.filterButton {
  margin: 0.8rem 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.8rem;
}

.filterContainer {
  padding: 1.6rem 2.4rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.8rem;
  grid-template-areas:
    'checkboxGroup'
    'picker'
    'fromField'
    'toField'
    'txIdField'
    'addressField'
    'filterButtons';
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  grid-area: checkboxGroup;
}
.picker {
  display: flex;
  flex-direction: column;
  grid-area: picker;
}
.fromField {
  display: flex;
  flex-direction: column;
  grid-area: fromField;
}
.toField {
  display: flex;
  flex-direction: column;
  grid-area: toField;
}
.txIdField {
  display: flex;
  flex-direction: column;
  grid-area: txIdField;
}
.addressField {
  display: flex;
  flex-direction: column;
  grid-area: addressField;
}

.filterButtons {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 3rem;
  margin-top: 1rem;
  grid-area: filterButtons;
}

.resetButton {
  min-width: 140px;
}

.submitButton {
  min-width: 140px;
}

@media min-small-tablet {
  .container {
    padding-right: 0;
    padding-left: 0;
  }
  .filterButton {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media min-large-phone {
  .filterContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'checkboxGroup checkboxGroup'
      'picker picker'
      'fromField txIdField'
      'toField addressField'
      'filterButtons filterButtons';
  }
}

@media min-large-tablet {
  .filterContainer {
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem 2.4rem;
    grid-template-areas:
      'checkboxGroup picker'
      'fromField txIdField'
      'toField addressField'
      'filterButtons filterButtons';
  }
  .filterButton {
    display: flex;
    flex-direction: row;
  }
}

@media min-laptop {
  .filterContainer {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.8rem 2.4rem;
    grid-template-areas:
      'checkboxGroup fromField txIdField'
      'picker toField addressField'
      'filterButtons filterButtons .';
  }

  .picker {
    justify-self: flex-start;
    min-width: 40.6rem;
  }
}

@media max-large-phone {
  .container {
    padding-bottom: 20rem,
  }
}