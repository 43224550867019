.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: 'Inter',sans-serif;
    padding: 2rem;
    border-radius: 10px;
}

.successContainer {
    font-family: 'Inter',sans-serif;
    max-width: 40rem;
    min-height: 30rem;
    padding: 2rem;
    margin: 2rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
}

.input { 
    width: 100%;
}

.formBody {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.8rem;
}

.formSubmit {
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;
    border-top: 2px solid var(--color-logo);
    margin-top: 2rem;
    padding-top: 2rem;
}

.heading {
    color: var(--color-logo);
    font-weight: 400;
    font-size: 2.3rem;
    margin-bottom: 1rem;
}

.subHeading {
    font-weight: 400;
    font-size: var(--md-sys-typescale-headline-smaller-size);
}

.successMessage {
    font-size: 1.9rem;
    font-weight: 400;
    color: var(--color-success);
}

.infoMessage {
    font-size: 1.4rem;
}