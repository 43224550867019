.signIn {
  width: 350px;
  min-height: 350px;
  padding: 2rem;
  background-color: rgba(224, 227, 227, 0.12);
  /* opacity: 0.6; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-on-surface);
}

.signInForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.signInForm h1 {
  text-align: center;
  margin-bottom: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputWrapper > input {
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
}

.inputWrapper > label {
  margin-bottom: 1rem;
}

.formikError {
  color: var(--md-ref-palette-error-50);
}

.errorMsg {
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
  width: 100%;
  /* padding-top: 7%; */
  color: red;
}

.signInBtn {
  font-size: 1.5rem;
  font-weight: bold;
}

.signInAdditional {
  font-size: 1.4rem;
  margin-top: 1.6rem;
}

.singInAdditionalBtn {
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: #fff;
  background-color: transparent;
  padding: 1rem;
  width: 100%;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin-top: 3rem;
}
