.container {
  padding-right: 1.2rem;
  margin-top: 4.8rem;
}

.table {
  overflow: hidden;
  border-radius: 1.2rem;
  margin-right: 0.3rem;
}

.headline {
  composes: type-headline-small from global;
  margin: 0;
  margin-bottom: 1.6rem;
}
