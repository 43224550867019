.container {
    display: flex;
    gap: 1.6rem;
}

.currencyContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -0.5rem;
}

.info {
    padding-top: 0.4rem;
}