@value max-small-tablet, min-small-tablet from "../../styles/css-values/breakpoints.module.css";

.button:hover {
  opacity: 1;
}

.button:focus {
  outline: none;
}

.menu {
  display: none;
}

.menuText {
  margin-top: 12vh;
}

.closeButton {
  display: none;
}

.info {
  display: none;
}

@media max-small-tablet {
  .info {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    
  }

  .closeButton {
    color: var(--color-on-surface);
    display: flex;
    position: absolute;
    right: 1rem;
    margin-top: 2rem;
    margin-right: 1rem;
    opacity: 1;
  }

  .menu {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-surface);
    position: absolute;
    overflow: hidden;
    top: -100vh;
    opacity: 1;
    z-index: 100;
    border-radius: 10px;
  }

  .enter {
    display: flex;
    width: 100vw;
    height: 100%;
    margin: 0;
    background-color: var(--color-surface);
    position: fixed;
    top: 0;
    overflow: hidden;
    opacity: 1;
    z-index: 100;
    border-radius: 10px;
    transition: all 0.4s ease-in;
    -webkit-transition: all 0.4s ease-in;
  }

  .exit {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-surface);
    position: absolute;
    overflow: hidden;
    top: -100vh;
    opacity: 0.5;
    z-index: 100;
    border-radius: 10px;
    transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
  }
}