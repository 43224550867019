.container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0.3rem;
  border-radius: 10px;
  max-height: fit-content;
  flex-grow: 1;
  overflow: hidden;
  word-break: break-all;
}

.detail {
  flex: 1;
  display: flex;
  flex-direction: row;
  /* this makes sure address field is positioned to the bottom when wallets are displayed in a row and some don't have a destionation tag  */
  align-items: stretch;
  gap: 0.8rem;
}

.detail span {
  min-width: 150px;
}

.detailHighlight {
  font-weight: 500;
  overflow: hidden;
  display: flex;
  gap: 1rem;
}

.copyLogo {
  border-radius: 0px 10px 0px 10px;
  color: var(--color-on-surface)
}

.copyLogo:hover {
  cursor: pointer;
  color: var(--color-logo);
}

.copyLogo:active {
  box-shadow: 0px 0px 3px var(--color-logo);
  transform: translateY(1px) ease;
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.advanced {
  max-height: 0;
  transition: height 0.5s ease-out;
  overflow: hidden;
}
  
.advancedShow {
  overflow: hidden;
  max-height: 250px;
  transition: height 1s ease-in;
  opacity: 1;
}

.advancedBtn {
  margin-top: 1rem;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 2rem;
  }
}

@media screen and (max-width: 655px) {
  .container {
    padding: 2rem;
  }
  .detail span {
    min-width: 78px;
  }
  .advanced-btn {
    display: flex;
    justify-content: center;
  }
}