@value min-small-tablet, max-small-tablet from "../../styles/css-values/breakpoints.module.css";


.additionalWrapper {
    color: var(--color-on-surface);
    display: none;
    min-width: 10%;
    margin-right: 2rem;
    margin-left: 1rem;
    padding-top: 0.65rem;
}

.additionalWrapperMobile {
    color: var(--color-on-surface);
    display: none;
    min-width: 10%;
    margin-top: 0.6rem;
    margin-right: 1rem;
    margin-left: 2rem;
}
  
.headerAdditional {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.userInfo {
    font-size: 1.75rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 1.7rem;
    margin-bottom: 1.7rem;
}

.userInfoMobile {
    /* font-size: 1.4rem; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border {
    border: 2px solid;
    border-radius: 10px;
    border-color: var(--txt-dark);
    margin-top: 2rem;
}



.headerIcon{
    color: var(--color-on-surface)
}

.headerLanguage {
    margin-right: 15px;
}

.headerSettings {
    color: #fff;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
}

.textIcon {
    position: relative;
    margin-bottom: 0.3rem;
    margin-right: 1rem;
}

.headerNav {
    color: inherit;
    text-decoration: none;
}

.settingsOptions {
    position: absolute;
    width: 250px;
    top: 35px;
    right: 0px;
    padding: 1rem;
    background-color: var(--color-surface);
    color: var(--color-on-surface);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;
}

.infoHeader{
    display: flex;
    align-items: center;
    margin-left: -1rem;
}

.logo {
    height: 30px;
}

@media min-small-tablet {
    .additionalWrapper{
        display: grid;
    }
    .logo {
        display: none;
    }
}
@media max-small-tablet {
    .additionalWrapperMobile {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .headerAdditionalMobile {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        gap: 1rem;
    }
    .headerSettings {
        color: var(--txt-dark);
    }
    .textIcon {
        position: relative;
        margin-bottom: 0.3rem;
        margin-right: 1rem;
    }
}