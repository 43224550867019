.summary {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin: 0.3rem;
    border-radius: 10px;
    max-height: fit-content;
    flex-grow: 1;
}
  
.wide {
    width: 40%;
}

.summary h1 {
    padding-bottom: 1rem;
}

.summary_info {
    display: flex;
    flex-direction: column;
    word-break: normal;
}

.summary_info span {
}

.number {
    font-size: 18px;
    font-weight: 500;
}

.bold {
    font-weight: 600;
}