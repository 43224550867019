.container {
  margin-top: 4.8rem;
  padding-bottom: 10rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.pageHeader {
  display: flex;
  margin-bottom: 3.2rem;
  gap: 1rem;
  align-items: center;
}

.pageHeadline {
  composes: type-headline-medium from global;
}

.pageTab {
  font-family: 'Inter', sans-serif;
  font-size: 1.9rem;
  padding: 0 24px;
  color: var(--color-on-surface);
  background-color: var(--color-disabled-container);
  border-radius: 15px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.pageTabColored {
  font-family: 'Inter', sans-serif;
  font-size: 1.9rem;
  padding: 0 24px;
  color: var(--color-surface);
  background-color: var(--color-logo);
  border-radius: 15px;
  cursor: pointer;
}

.pageTab:hover {
  color: var(--color-surface);
  background-color: var(--color-logo);
}

.markets {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  flex-wrap: wrap;
  gap: 1.6rem;
}
