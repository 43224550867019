.fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.legend {
  composes: type-label-large from global;
  display: flex;
  width: 100%;
  margin-bottom: 0.8rem;
}

.legend::before {
  content: '';
  display: block;
  height: 1px;
  margin-bottom: 0.6rem;
  margin-right: 0.8rem;
  flex-grow: 0;
  width: 1.2rem;
  align-self: flex-end;
  background-color: var(--color-outline);
}

.legend::after {
  content: '';
  display: block;
  height: 1px;
  margin-bottom: 0.6rem;
  margin-left: 0.8rem;
  flex-grow: 1;
  align-self: flex-end;
  background-color: var(--color-outline);
}
