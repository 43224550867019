.table {
  --border-radius: 1.6rem;
  --header-offset: 2.4rem;

  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
}

.table thead {
  border-radius: var(--border-radius);
}

.table thead th {
  padding: 0.5rem 1.6rem;
  text-align: start;
  font-size: medium;
}
.table thead th.amountCol {
  text-align: start;
}

.table thead th.statusCol {
  text-align: center;
}

.table td {
  padding: 0rem 1.6rem;
  text-align: start;
  font-size: small;
  cursor: pointer;
}

.table td:first-child {
  max-width: 15rem;
}

.table thead th:first-child {
  padding-left: 4.2rem;
}

.table td.amountCol {
  text-align: start;
  overflow: hidden;
  max-width: 20rem;
  word-break: break-all;
}

.table td.statusCol {
  text-align: center;
  padding-left: 4rem;
}

.table tbody:first-of-type tr:first-of-type th {
  border-top-left-radius: var(--border-radius);
}
.table tbody:first-of-type tr:first-of-type th {
  border-top-right-radius: var(--border-radius);
}

/* date heading */
.table tbody tr:first-of-type th {
  text-align: start;
  padding: 0.8rem 1.6rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--md-ref-palette-error-50);
  margin-top: 2rem;
}

.loaderItem {
  padding-bottom: 8rem;
}

.lineBreak {
  display: none;
}

@value breakpoints: "styles/css-values/breakpoints.module.css";
@value max-laptop from breakpoints;
@value min-laptop from breakpoints;
@value max-large-tablet from breakpoints;
@value min-large-tablet from breakpoints;
@value max-small-tablet from breakpoints;
@value min-small-tablet from breakpoints;
@value max-large-phone from breakpoints;
@value min-large-phone from breakpoints;

@media max-large-tablet {
  .table td {
    padding-left: 0.1rem;
    padding-right: 0.4rem;
  }
  .statusCol {
    display: none;
  }
  .amountCell {
    display: none;
  }
  .success {
    color: var(--md-sys-color-success-dark);
  }
  .fail {
    color: var(--md-ref-palette-error-50);
  }
  .pending {
    /* generate color with yellow hue for --color-pending  */
    color: var(--md-ref-palette-neutral-60);
  }
  .table thead th.amountCol {
    text-align: end;
  }
  .table td.amountCol {
    text-align: end;
    width: 13.5rem;
  }
  .lineBreak {
    display: inline;
  }
}

@media max-laptop {
  .createdAtHeader > * {
    padding-left: 1rem;
    text-align: start;
    font-size: smaller;
    opacity: 0.9;
  }
  .createdAtHeader {
    /* background: linear-gradient(black 0 98%, white 98% 100%); */
    background-color: #121919;
  }
  .createdAtHeaderDark > * {
    padding-left: 1rem;
    text-align: start;
    font-size: smaller;
  }
  .createdAtHeaderDark {
    background-color: #e0e3e3;
  }
  .createdAtCol {
    display: none;
  }
}

@media min-laptop {
  .table td.statusCol {
    padding-right: 4rem;
  }
  .createdAtHeader {
    display: none;
  }
  .createdAtHeaderDark {
    display: none;
  }
  .table tbody:first-of-type tr:nth-of-type(2) td:first-child {
    border-top-left-radius: var(--border-radius);
  }
  .table tbody:first-of-type tr:nth-of-type(2) td:last-child {
    border-top-right-radius: var(--border-radius);
  }
}
