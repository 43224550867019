.container {
  /* stretch children to fit */
  display: inline-flex;
  flex-direction: column;
}

.fieldWrapper {
  --input-height: 4rem;

  display: inline-grid;
  grid-template-areas:
    'label label label'
    'left input right'
    'context context context';

  grid-template-columns: auto 1fr auto;
}

.fieldWrapperLeft {
  grid-area: left;
  z-index: 1;
  align-self: flex-start;
  height: var(--input-height);
  display: flex;
  align-items: center;
}

.leftButton {
  margin-left: 0;
  margin-right: 0.2rem;
}

.leftDecoration:first-child {
  margin-left: 1.2rem;
}

.leftDecoration {
  margin-right: 1.6rem;
}

.fieldWrapperRight {
  grid-area: right;
  z-index: 1;
  align-self: flex-start;
  height: var(--input-height);
  display: flex;
  align-items: center;
}

.rigthButton {
  margin-right: 0;
  margin-left: 0.2rem;
}

.rightDecoration:last-child {
  margin-right: 1.2rem;
}
.rightDecoration {
  margin-left: 0.8rem;
}
.rightDecoration:first-child {
  margin-left: 1.6;
}

.rightValidation:last-child {
  margin-right: 1.2rem;
}
.rightValidation {
  margin-left: 0.8rem;
}
.rightValidation:first-child {
  margin-left: 1.6;
}

.label {
  composes: type-label-medium from global;
  grid-area: label;
  margin-bottom: 0.2rem;
  display: inline-block;
}

.input {
  composes: type-body-medium from global;
  grid-area: input;
  grid-column: 1 / -1;
  height: var(--input-height);
  /* 
    when wrapper does not render surrounding components, the padding will be 1.6rem.
    otherwise padding will hug surrounding components. 
    */
  padding-left: max(1.6rem, var(--left-width));
  padding-right: max(1.6rem, var(--right-width));
  border-width: 0;
  border-radius: 10px;
  /* border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-on-surface); */
  background-color: var(--color-background);
  color: var(--color-on-surface);
}

.input:placeholder-shown {
  opacity: 0.9;
}

.input::placeholder {
  color: var(--color-on-surface-variant);
  opacity: 0.5;
}

.input:focus {
  outline: none;
  border-bottom-color: transparent;
  opacity: 1;
  box-shadow: 0 0 0 2px var(--color-on-surface);
  border-radius: 10px;
}

.errorMessage,
.description {
  grid-area: context;
}

/* ============== modifiers ================== */

.fieldWrapper:global(.--isValid) {
  color: var(--color-success);
}

.input:global(.--isValid) {
  border-bottom-color: var(--color-success);
}

.input:global(.--isValid):focus {
  border-bottom-color: transparent;
  box-shadow: 0 0 0 2px var(--color-success);
}

/* isInvalid */

.fieldWrapper:global(.--isInvalid) .rightValidation {
  color: var(--md-ref-palette-error-50);
}

.label:global(.--isInvalid) {
  animation: var(--animation-shake);
  transform: translate3d(0, 0, 0);
  color: var(--md-ref-palette-error-50);
}

.input:global(.--isInvalid) {
  border-bottom-color: var(--md-ref-palette-error-50);
}

.input:global(.--isInvalid):focus {
  border-bottom-color: transparent;
}

.input:global(.--isInvalid):focus {
  box-shadow: 0 0 0 2px var(--md-ref-palette-error-50);
}

/* isDisabled */
.fieldWrapper:global(.--isDisabled) {
  color: var(--color-disabled-content);
}

.input:global(.--isDisabled) {
  background-color: var(--color-disabled-container);
  color: var(--color-disabled-content);
  border-bottom-color: var(--color-disabled-border);
  cursor: auto;
}

/* remove search icon for search fields */
.input::-webkit-search-cancel-button,
.input::-webkit-search-decoration {
  -webkit-appearance: none;
}
