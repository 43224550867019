.mainHeadline {
  composes: type-title-large from global;
  margin-bottom: 2rem;
}

.balanceOverview {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.latestTransactions {
  flex-grow: 1;
}

.balanceInfo {
  flex-grow: 0;
}

.latestTransactionsTitle {
  composes: type-title-large from global;
  margin-bottom: 0.5em;
}

.roundedContainer {
  border-radius: 1.2rem;
  padding: 2.4rem;
  height: 100%;
}

.dataList {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.dataList > div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.8rem;
}

.dt {
  composes: type-title-medium from global;
  font-weight: bold;
}
.dt::first-letter {
  text-transform: capitalize;
}

.dd {
  composes: type-body-large from global;
}

.myWalletsSection {
  margin-top: 4.8rem;
  grid-template-rows: auto 1fr;
  flex-grow: 1;
  display: grid;
  grid-auto-columns: 0px;
  gap: 0.8rem 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.myWalletsSectionFillers {
  grid-template-rows: auto 1fr;
  flex-grow: 1;
  display: grid;
  grid-auto-columns: 0px;
  gap: 0.8rem 1.6rem;
}

.myWalletsHeadline {
  composes: type-title-large from global;
  position: sticky;
  left: 0;
}

.fillersTabs {
  display: flex;
  font-size: 2rem;
  gap: 1rem;
  padding-top: 1rem;
  align-items: center;
}

.fillersTab {
  font-family: 'Inter', sans-serif;
  font-size: 1.9rem;
  padding: 0 24px;
  color: var(--color-on-surface);
  background-color: var(--color-disabled-container);
  border-radius: 15px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.fillersTabColored {
  font-family: 'Inter', sans-serif;
  font-size: 1.9rem;
  padding: 0 24px;
  color: var(--color-surface);
  background-color: var(--color-logo);
  border-radius: 15px;
  cursor: pointer;
}

.fillersTab:hover {
  color: var(--color-surface);
  background-color: var(--color-logo);
}

.listItem {
  grid-row: 2;
  display: grid;
  overflow: hidden; /* For some reason, this helps myWalletsSection not expand past it's boundaries */
}

.filler {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.8rem 1.6rem;
}

.depositLink {
  display: grid;
  cursor: pointer;
  outline: none;
  position: relative;
}

@value breakpoints: "styles/css-values/breakpoints.module.css";
@value min-small-tablet from breakpoints;
@value min-large-tablet from breakpoints;
@value max-laptop from breakpoints;
@value max-small-desktop from breakpoints;
@value phone from breakpoints;
@value small-tablet from breakpoints;
@value large-tablet from breakpoints;
@value laptop from breakpoints;
@value large-desktop from breakpoints;
@value small-desktop from breakpoints;

@value large-tablet-to-small-desktop: min-large-tablet and max-small-desktop;

@value max-small-tablet {
}

@media large-desktop {
  .myWalletsSection {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  .myWalletsSectionFillers {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media small-desktop {
  .myWalletsSection {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .myWalletsSectionFillers {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media large-tablet-to-small-desktop {
  .header {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .headerContainer:last-child {
    grid-column: 2 / -1;
  }
}

@media laptop {
  .myWalletsSection {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .myWalletsSectionFillers {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media large-tablet {
  .balanceInfo {
    flex-grow: 1;
  }

  .myWalletsSection {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .myWalletsSectionFillers {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  /* remove the last item */
  /* .listItem:nth-of-type(n + 3) {
    display: none;
  } */
}

@media small-tablet {
  .balanceInfo {
    flex-grow: 1;
  }

  .myWalletsSection {
    grid-template-columns: 65% 65% 65%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    margin-right: -2.4rem;
    padding-bottom: 1.2rem;
  }

  .myWalletsSectionFillers {
    grid-template-columns: 65% 65% 65% 65% 65% 65% 65%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    margin-right: -2.4rem;
    padding-bottom: 1.2rem;
  }
}

@media phone {
  .balanceOverview{
    width: 100vw;
    padding-right: 1.6rem;
  }

  .balanceInfo {
    flex-grow: 1;
  }

  .dataList > div {
    flex-direction: column;
  }
  .myWalletsSection {
    grid-template-columns: 95% 95% 95%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    width: 100vw;
    margin-right: -1.6rem;
    margin-left: -1.6rem;
    padding-bottom: 1.2rem;
  }

  .myWalletsSectionFillers {
    grid-template-columns: 95% 95% 95% 95% 95% 95% 95%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    margin-right: -1.6rem;
    margin-left: -1.6rem;
    padding-bottom: 1.2rem;
  }
}
