/* .ka {
  font-family: 'FiraGo', sans-serif;
}

.en {
  font-family: 'Inter', sans-serif;
} */

/* .bg-wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: -1;
}

.bg-wrapper > div {
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 500px;
  background-repeat: no-repeat;
} */

/* .bg-wrapper > .b {
  transform: scaleY(-1);
} */

/* .bg-wrapper {
  background: url('./assets/bgElement5.svg'), var(--bg-main);
  background-size: cover;
  background-position: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
  will-change: transform;
}

.bg-wrapper-light {
  background: url('./assets/bgElement40.svg'), var(--bg-main);
  background-size: cover;
  background-position: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
  will-change: transform;
} */

/* .dark-theme {
  --bg-header: #1e2a31;
  --bg-main: #22313a;
  --bg-form: #22313a;
  --alert-bg: #f5f5f5;
  --bg-main-border: rgba(34, 49, 58, 0.5);
  --border: #d4d4d465;
  --dashboard-bg: #22313a;
  --srf-main: rgba(42, 56, 65, 0.7);
  --srf-main-light: rgba(42, 56, 65, 0.5);
  --srf-accent: rgba(245, 245, 245, 0.2);
  --btn-main: #d4d4d4;
  --btn-filter: #959595;
  --txt-main: #fff;
  --txt-accent: #000;
  --txt-dark: #828b90;
  --form-bg: #3a464d;
  --land-txt-dark: #b0b1b2;
  --primary: #ff9800;
  --bg-icon: #2a3841;
}
.light-theme {
  --bg-main: #d7d7d7;
  --bg-main-border: rgba(215, 215, 215, 0.5);
  --bg-form: #f5f5f5;
  --alert-bg: #22313a;
  --dashboard-bg: rgba(255, 255, 255, 0.5);
  --border: #d4d4d4;
  --srf-main: rgba(255, 255, 255, 0.7);
  --srf-main-light: rgba(255, 255, 255, 0.5);
  --srf-accent: rgba(245, 245, 245, 0.5);
  --btn-main: #d4d4d4;
  --btn-filter: #fff;
  --txt-main: #000;
  --txt-accent: #fff;
  --txt-dark: #000;
  --form-bg: #3a464d;
  --land-txt-dark: #000;
  --primary: #ff9800;
  --icon-bg: #000;
} */

/* Global input styles */
.form-wrapper {
  font-size: 1.5rem;
  font-weight: 300;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  max-width: 40rem;
  background-color: var(--bg-form);
  padding: 2rem;
  border-radius: 10px;
  display: block;
  z-index: 10;
  color: var(--txt-main);
  text-transform: capitalize;
}

.input-box {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: capitalize;
}

.form-input {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 1rem;
  margin-bottom: 10px;
}

.input::placeholder {
  color: var(--txt-dark);
}

.input:focus {
  border-color: #2684ff;
}

.input:focus::placeholder {
  color: transparent;
}

.description-box {
  display: flex;
  flex-direction: column;
}

.description-input {
  border-radius: 5px;
  outline: none;
  font-size: 1.4rem;
  padding: 0.7em;
  font-family: 'Inter';
  background-color: #fff;
  color: black;
  height: 8rem;
  margin-bottom: 1.3em;
  resize: none;
  border: none;
}

.description-input::placeholder {
  color: var(--txt-dark);
}

.description-input:focus::placeholder {
  color: transparent;
}

.amount-inputs {
  display: flex;
  justify-content: space-between;
}

/* .amount-input {
  width: 45%;
} */

.close-icon {
  margin-left: auto;
  color: var(--txt-dark);
  cursor: pointer;
}

.close-icon:hover {
  color: var(--txt-main);
}

.form-icon {
  margin-right: 0.7em;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-title {
  display: flex;
  align-items: center;
  color: var(--txt-main);
}

.form-title-text {
  text-transform: capitalize;
}

.form-option {
  margin: 1.5rem 0;
}

.option-coin-img {
  display: flex;
  justify-content: center;
}

.select-wide {
  width: 100%;
  margin: 0.5em auto;
}

.select-wide input {
  height: 30px;
}

.select-wide div {
  background-color: #fff !important;
  color: black !important;
  cursor: pointer !important;
  /* display: flex;
  align-items: center; */
}

.select-wide img {
  height: 20px !important;
  vertical-align: middle;
  margin-right: 5px;
}

.bold {
  font-weight: 500;
}

.networks {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.network-button {
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  color: #000;
  background-color: lightgray;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  /* margin: 0 .5rem; */
  cursor: pointer;
}

.active-network {
  background-color: var(--primary);
}
