.errorList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.error {
  display: flex;
  gap: 0.8rem;
  align-items: start;
  border-radius: 0.6rem;
  line-height: 1.4em;
  padding: 1.2rem;
  padding-right: 2.4rem;
  position: relative;
}

.errorIcon {
  line-height: inherit;
}

.errorMessage {
  composes: type-body-medium from global;
  line-height: inherit;
  flex-grow: 1;
}

.buttonContainer {
  background-color: transparent;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 3px;
}
