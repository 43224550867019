@value max-small-tablet from "../../styles/css-values/breakpoints.module.css";

.container {
  margin-top: 1rem;
  padding-bottom: 10rem;
}

.container hgroup {
  margin-bottom: 3.2rem;
}

.pageHeadline {
  composes: type-headline-medium from global;
  margin-bottom: 0.8rem;
}

.wallets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  flex-wrap: wrap;
  gap: 1.6rem;
}

.roundedContainer {
  display: grid;
  gap: 1.6rem;
  border-radius: 1.2rem;
  padding: 3.2rem;
  height: 100%;
  width: 100%;
}

@media max-small-tablet {
  .container{
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    margin-top: 2rem;
  }
}