.copyLogo {
    border-radius: 0px 10px 0px 10px;
    color: var(--color-on-surface)
}
  
.copyLogo:hover {
    cursor: pointer;
    color: var(--color-logo);
}

.copyLogo:active {
    box-shadow: 0px 0px 3px var(--color-logo);
    transform: translateY(1px) ease;
}