.ip-whitelist-wrapper {
  width: 100%;
  max-width: 1224px;
  color: var(--txt-main);
  display: flex;
  font-family: 'Inter', sans-serif;
  padding: 1rem;
  border-radius: 10px;
  justify-content: space-between;
  position: relative;
  /* justify-content: space-around; */
}

.ip-whitelist-wrapper h1 {
  font-size: 2rem;
  padding: 0 1rem;
}

.ip-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  margin-right: 50px;
}

.ip-list {
  display: flex;
  flex-direction: column;
}

.ip-not-found {
  padding-left: 1rem;
}

.ip {
  display: block;
  width: 100%;
  padding-left: 2rem;
  margin: 1rem 0;
  background-color: var(--srf-main);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-new-ip {
  display: flex;
  flex-direction: column;
  padding-top: 0.2rem;
  margin-left: -0.3rem;
  gap: 1rem;
  width: 22rem;
}

.ip-input {
  font-family: 'Inter', sans-serif;
  font-size: large;
  padding-left: 1rem;
  height: 4rem;
  border-radius: 10px;
  border: hidden;
  background-color: var(--color-background);
  color: var(--color-on-surface);
  width: 300px;
}

.remove-icon {
  cursor: pointer;
}

.tfa-check {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30rem;
  gap: 0.8rem;
  padding: 1rem;
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid var(--color-logo);
  border-radius: 10px;
}
.tfa-check > span {
  margin-top: 2.5rem;
  &.error {
    margin-top: 0;
  }
}
.tfa-check-close {
  position: absolute;
  display: flex;
  width: 2rem;
  height: 3rem;
  right: 0;
}

.enable-tfa-message {
  display: flex;
  align-items: center;
  color: var(--md-ref-palette-error-50);
  height: 5rem;
}

.inline-error {
  margin-top: 2.5rem;
}

@media screen and (max-width: 996px) and (min-width: 811px) {
  .ip-whitelist-wrapper {
    width: 80vw;
  }
}

@media screen and (max-width: 810px) {
  .ip-whitelist-wrapper {
    flex-direction: column;
    width: 75vw;
    gap: 2rem;
  }

  .ip-list-wrapper {
    margin-left: 1rem;
  }

  .add-new-ip {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 599px) {
  .ip-whitelist-wrapper {
    width: 91vw;
  }
}