@value max-small-tablet, min-small-tablet, min-small-desktop from "../../styles/css-values/breakpoints.module.css";

.container {
  padding: .1rem;
  /* opacity: 0.6; */
  border-radius: 10px;
  display: grid;
  flex-direction: row;
  align-items: center;
  color: #fff;
  background: linear-gradient(90deg, var(--color-logo) 0%,
   var(--color-logo-inverse) 25%, var(--color-logo) 50%, var(--color-logo) 75%, 
   var(--color-logo-inverse) 85%, var(--color-search-bar) 100%);
  margin-bottom: 5rem;
}

.mobileScreen {
  display: none;
}

.advanced {
  display: flex;
  flex-flow: row;
  align-items: stretch;
  width: 100%;
  border: 0;
}

.advanced > :nth-child(2) {
  margin-left: -1.5rem;
  border-radius: 0 !important;
}

.advanced > :nth-child(3) {
  margin-left: -1.5rem;
  flex-shrink: 1;
  flex-grow: 2;
  border-radius: 0 !important;
}


.navigate{
  font-weight: 600;
  font-size: 1.5rem;
}

.searchButton {
  margin-left: 0.8rem;
}

.header {
  display: none;
}

.logo {
  margin-top: 0.7rem;
  margin-left: 2rem;
  height: 40px;
}

.largeScreen {
  display: none;
}

.smallScreen {
  display: none;
}

.searchIcon {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  margin-bottom: 2px;
  transform: rotate(90deg);
}

@media all and (max-width: 599px) {
  
  .advanced {
    width: 100%;
  }
  .advanced > * {
  }
}

@media min-small-tablet {
  .header {
    display: inherit;
  }
  .largeScreen {
    display: inherit;
  }
}

@media max-small-tablet {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100vw;
    border-radius: 0px 0px 10px 10px;
    z-index: 10;
    margin-bottom: 0rem;
    background: var(--color-search-bar);
  }
  .mobileScreen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .7rem;
  }
  .smallScreen {
    display: inherit;
    margin-bottom: 0.5rem;
    transition: 3s ease-in;
  }
  .advanced {
    margin-right: 1rem;
    padding-left: 2rem;
  }
}