@value max-large-tablet from "../../styles/css-values/breakpoints.module.css";

.container {
    display: grid;
    /* grid-auto-flow: column; */
    grid-template-columns: repeat(auto-fit, minmax(auto, 32rem));
    gap: 2rem;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    padding-bottom: 8rem;
}

.heading {
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: var(--md-sys-typescale-headline-large-size);
    margin: 2rem 1rem 2rem;
    padding: 2rem;
}

@media max-large-tablet {
    .container {
        flex-direction: column;
    }
}