.container {
  display: inline-flex;
  flex-direction: column;
}

.button {
  composes: type-label-large from global;
  width: 100%;
  display: flex;
  align-items: center;
  height: 4rem;
  padding-left: 1.6rem;
  padding-top: 0.4rem;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  color: var(--color-on-surface);
  cursor: pointer;
  position: relative;
}

.button::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: var(--color-primary); */
  opacity: 0;
}

.button:hover::before {
  opacity: var(--interaction-layer-hovered);
}

.focusRing:global(.--isKeyboardFocused) {
  box-shadow: 0 0 0 2px var(--color-on-surface) inset;
}

.button:focus {
  outline: none;
  border-bottom-color: transparent;
  border-radius: 0px;
}

.button:focus::before {
  opacity: var(--interaction-layer-focused);
}

.defaultValue {
  font-style: italic;
}

.right {
  margin-left: 0.8rem;
  margin-right: 1.2rem;
}

/* isDisabled */
.container:global(.--isDisabled) {
  color: var(--color-disabled-content);
}

.container:global(.--isDisabled) .button {
  background-color: var(--color-disabled-container);
  color: var(--color-disabled-content);
  cursor: auto;
}

/* isInvalid */
.container:global(.--isInvalid) .right > *:first-child {
  color: var(--md-ref-palette-error-50);
}
