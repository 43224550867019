.container {
  display: inline-flex;
  flex-direction: column;
}

.label {
  composes: type-label-medium from global;
  grid-area: label;
  margin-bottom: 0.2rem;
  display: inline-block;
}

.input {
  composes: type-body-medium from global;
  width: 100%;
  height: 4rem;
  background-color: var(--color-disabled-container);
  color: var(--color-disabled-content);
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0 1.2rem;
}

.description {
  composes: type-body-medium from global;
  margin-top: 0.4rem;
}

.retryFocusRing {
  display: inline-flex;
  border-radius: 0.8rem;
  position: relative;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
}

.retryFocusRing:global(.--isKeyboardFocused)::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  box-shadow: 0 0 0 2px var(--color-on-surface) inset;
}

.retryButton {
  background-color: transparent;
  width: 100%;
  border-radius: inherit;
  padding: 0 0.8rem;
  cursor: pointer;
  border-width: 0;
  display: inline-flex;
  justify-content: center;
  gap: 0.8rem;
  align-items: center;
  transition: background-color 0.15s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* used for: hover, focus, pressed */
.retryButton::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: var(--color-primary);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.retryButton:hover::before {
  opacity: var(--interaction-layer-hovered);
}

/* focused state */

.retryButton:focus {
  outline: none;
}

.retryButton:focus::before {
  opacity: var(--interaction-layer-focused);
}
