.alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  text-align: center;
  padding: 0.8rem;
  /* opacity: 0.9; */
  background: var(--alert-bg);
  color: #333;
  border-radius: 5px;
  z-index: 2000;
  font-weight: 500;
}

.alert-danger {
  color: red;
}

.alert-success {
  color: green;
}
