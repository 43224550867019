.container {
  composes: type-body-large from global;
}

.container span {
  opacity: 0.9;
}

.container strong {
  opacity: 1;
}

.container strong span {
  font-size: larger;
}

.big {
  font-size: xx-large;
  word-break: break-all;
}
