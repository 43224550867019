.sentientBackground {
  min-height: 100vh;
  color: var(--color-on-background);
  background-color: var(--color-background);
  padding: 72px;
}

.sentientBackground :global(.h) {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.sentientBackground form {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* place-items: start; */
  gap: 16px;
  margin-bottom: 64px;
}

.sentientBackground form :global(.h) {
  grid-column: 1/4;
}

.secondaryHeading {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 40px 0 24px;
  max-width: 60ch;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: start;
  gap: 16px;
  padding-bottom: 64px;
}

.buttons header {
  grid-column: 1 / -1;
}

.buttons > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.defaultSurface {
  padding: 24px;
  min-width: 200px;
  min-height: 150px;
}

.surfaceGrid {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.surfaceGrid > * {
  min-width: 300px;
}
