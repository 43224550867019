.heading {
  margin: 2em 0 1em;
}

.section {
  margin: 1.6rem 0;
}

.section [class^='DefaultField'] {
  margin-right: 1.6rem;
}
