.invoicePage {
  display: flex;
  padding: 2rem 10rem;
  height: auto;
  justify-content: center;
  font-family: 'Inter', sans-serif;
}

.invoiceHeaderOptions {
  display: flex;
  align-items: center;
}

.invoiceTitle {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: var(--txt-main);
}

.invoiceTitle > img {
  margin-right: 10px;
}

.invoiceContainer {
  width: 100%;
  max-width: 110rem;
  border-radius: 10px;
  padding: 1.6rem;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.invoiceMain {
  display: grid;
  grid-auto-flow: column;
}

.invoiceInfo {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 2rem;
  border-radius: 10px;
  /* max-height: 270px; */
  font-size: 1.4rem;
  /* flex-grow: 1; */
  margin-right: 5px;
}

.invoiceInfo > h1 {
  margin-bottom: 2rem;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 10px;
  /* max-height: 270px; */
  font-size: 1.4rem;
  /* flex-grow: 1; */
  margin-right: 5px;
}

.addressLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addressInfo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 1.6rem;
}

.invoiceSummaryItem {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: 0.5fr 0.5fr;
  color: var(--color-on-surface);
  padding: 0.2rem 1rem 0.2rem;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 300;
}

.invoiceSummaryItem > span {
  font-weight: 500;
}
.copyButton {
  background-color: var(--btn-main);
  /* width: 10%; */
  width: 10%;
  max-width: 35px;
  height: 35px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: all 0.2s;
  margin-left: 5%;
}

.copyButton:active {
  transform: scale(0.8);
}

.addressInput {
  width: 100%;
  background-color: var(--color-search-bar);
  color: var(--color-logo);
  font-family: 'Inter', sans-serif;
  border-radius: 5px;
  outline: none;
  /* border: none; */
  padding: 1rem;
  margin-bottom: 10px;
  width: 85%;
}

.qrContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.invoiceSummaryItem .paid {
  color: green;
}

.invoiceSummaryItem .unpaid {
  color: red;
}

@media screen and (max-width: 996px) {
  .invoiceMain {
    grid-auto-flow: row;
  }

  .invoiceInfo {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .addressContainer {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 800px) {
  .invoiceContainer {
    padding: 2rem;
  }
}

@media screen and (max-width: 420px) {
  .invoiceContainer {
    margin-top: 20px;
  }
}

@media screen and (max-width: 655px) {
  .invoicePage {
    padding: 1rem;
  }
}
