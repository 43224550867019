.signUpContainer {
  padding: 1rem 0;
  color: var(--color-on-background);
  background-color: var(--color-background);
  /* opacity: 0.6; */
  border-radius: 10px;
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUp {
  font-family: 'Inter',sans-serif;
  width: 95%;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
}

.formError {
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  background-color: #af0606;
  border-radius: 3px;
  color: white;
  margin-bottom: 25px;
}

/* Header Styles */
.signUpHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.subHeading {
  font-weight: 400;
  font-size: var(--md-sys-typescale-headline-smaller-size);
}

.signUpAdditional {
  width: 100%;
  text-align: center;
  font-size: 1.7rem;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.signUpAdditional > span {
  color: var(--primary);
  cursor: pointer;
}

.signUpAdditional > span:hover {
  text-decoration: underline;
}

.signUpTitle {
  font-size: 2.3rem;
  font-weight: 300;
}

/* Type Styles */
.signUpTypes {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tab {
  display: flex;
  gap: 1rem;
}

.pairInput {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.pairInput .inputWrapper {
  width: 47.5%;
}

.pairInput .inputWrapper input {
  width: 100%;
}

/* Dropdown */

.signUpDropdown > * {
  margin-bottom: 1rem;
}

.dropdownFormikError {
  color: var(--md-ref-palette-error-50);
  margin-top: -30px;
}

.left {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 2fr;
  width: 100%;
}

.phoneNumber {
  width: 10px;
}

.phoneCode {
  display: flex;
  flex-direction: column;
  width: 8.5rem;
  gap: .22rem;
}

.label {
  composes: type-label-medium from global;
  grid-area: label;
}

.phoneCodeInput {
  composes: type-body-medium from global;
  grid-area: input;
  height: 4rem;
  padding-left: 1rem;
  border-width: 0;
  border-radius: 10px;
  background-color: var(--color-background);
  color: var(--color-on-surface);
}

/* CHECKBXO */
.signUpCheckbox {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.formikError {
  font-family: 'Inter',sans-serif;
  color: var(--md-ref-palette-error-50);
  margin-left: 2rem;
}
/* Business input */

.businessInput {
  position: relative;
  margin-bottom: 5rem;
}

.businessInput input {
  width: 100%;
}

.formSubmit {
  border-top: 2px solid var(--color-logo);
  margin-top: 2rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.pinField {
  width: 100%;
}

.resendButton {
  width: 10rem;
}

.formContainerSignUp {
  display: flex;
  flex-direction: column;
  color: var(--color-on-surface);
}

.headerSignUp{
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--color-logo)
}

.pinSignUp {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 0fr;
  gap: 1rem;
  width: 100%;
}

.verifyButtonSignUp {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

@media screen and (max-width: 770px) {
  .signUpTypes {
    flex-direction: column;
  }

  .type {
    width: 100%;
  }

  .pairInput {
    flex-direction: column;
    gap: 0.6rem;
  }

  .left {
    grid-template-columns: 1.25fr 2fr;
  }
}
