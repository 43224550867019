@value max-small-tablet from "../../styles/css-values/breakpoints.module.css";

.container {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 4.5rem;
    border-radius: 10px;
    margin-bottom: 10rem;
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.table {
    margin-top: 2.4rem;
    border-radius: 1.2rem 1.2rem 0 0;
    overflow: hidden;
}

.tableHeader {
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 988px) {
  .container {
    display: grid;
    grid-template-columns: auto 1fr;
  }
}

.mainHeadline {
  composes: type-headline-medium from global;
  margin: 0;
  display: flex;
  margin-right: 2rem;
}

.tabPanel {
  grid-column: 1 / 3;
}

@media max-small-tablet {
  .container {
    width: 100vw;
    margin-top: 2rem;
    padding: 0.8rem;
    padding-bottom: 5rem;
  }
  .tab {
    display: flex;
    justify-content: center;
  }
  .tabPanel {
    display: grid;
  }
  .mainHeadline{
    margin-bottom: 1.6rem;
  }
}
