@value max-small-tablet, min-small-tablet, max-large-tablet, min-large-tablet, max-laptop, min-laptop, max-small-desktop, min-small-desktop, max-large-desktop from "../../styles/css-values/breakpoints.module.css";


.signupPage {
    font-family: 'Inter',sans-serif;
    font-size: 1.4rem;
    color: var(--txt-main);
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

