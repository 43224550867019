.container {
  margin-bottom: 64px;
}

.container :global(.h) {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.container p {
  max-width: 60ch;
  margin-bottom: 1em;
}

.fields {
  margin-bottom: 32px;
}

.fields ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.fields li {
  flex-basis: 288px;
}

.fields li > * {
  min-width: 100%;
}

.checkboxes ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.checkboxes li {
}

.checkboxes li > * {
}
