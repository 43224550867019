.container {
    display: grid;
    gap: 0.7rem;
}

.title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.languageSelector {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.languageSelector::before {
    color: var(--color-logo);
}

.options img {
    margin-top: 0.6rem;
    height: 18px;
    width: 18px;
    border-radius: 50%;
}