@value max-small-tablet from "../../styles/css-values/breakpoints.module.css";

.container {
  padding: 2.4rem;
  border-radius: 1.2rem;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
}

.icon {
  top: 2.4rem;
  right: 2.4rem;
  width: 3.2rem;
  position: absolute;
  border-radius: 10px;
}

.currencyName {
  composes: type-title-large from global;
  line-height: 1em;
  white-space: nowrap;
}

.amount {
  composes: type-body-medium from global;
  margin-top: 0.8rem;
}

.amount :not(strong) {
  opacity: 0.9;
}

.pending {
  font-size: 1.2rem;
}

.actions {
  margin-top: 2.4rem;
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: flex-end;
  gap: 0.8rem;
  justify-content: flex-end;
}

.container:global(.--sizeLarge) .actions {
  grid-template-columns: auto auto;
}

@media max-small-tablet {
}