.horizontal {
  background-color: var(--color-outline);
  height: 1px;
  border: 0;
}

.vertical {
  background-color: var(--color-outline);
  width: 1px;
  height: auto;
  border: 0;
}
