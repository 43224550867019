.container {
  padding: 2rem;
  /* opacity: 0.6; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  min-height: 350px;
  justify-content: center;
}

.heading {
  margin-bottom: 3rem;
}

.counter {
  font-size: 1.5rem;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3rem;
}
