.outputs {
    padding: 2.4rem;
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.3rem;
    word-break: break-all;
    max-height: fit-content;
    width: 99%;
}
  
.outputs h1 {
    font-size: 2.3rem;
    padding: 1.5rem 1rem;
    width: 100%;
    border-radius: 5px;
    background-color: #252424;
    margin-bottom: 5px;
}

.outputs__users {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    font-weight: 300;
}

.outputs_user {
    display: flex;
    flex-direction: column;
    font-weight: 300;
}

.outputs_user__address {
    font-weight: 600;
    overflow: hidden;
}

.copyLogo {
    border-radius: 0px 10px 0px 10px;
    color: var(--color-on-surface);
}
  
.copyLogo:hover {
    cursor: pointer;
    color: var(--color-logo);
}
  
.copyLogo:active {
    box-shadow: 0px 0px 3px var(--color-logo);
    transform: translateY(1px) ease;
}

@media screen and (max-width: 996px) {
}