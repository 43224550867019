.formContainer {
    width: 350px;
    min-height: 350px;
    padding: 2rem;
    /* opacity: 0.6; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: var(--color-on-surface);
}

.formBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-top: 2rem;
}

.pin {
    margin-top: 4rem;
    display: flex;
    gap: 2rem;
}

.pinField {
    width: 100%;
}

.resendButton {
    width: 10rem;
}

.verifyButton {
    justify-content: center;
    margin-top: 4rem;
}

.formContainerSignUp {
    display: flex;
    flex-direction: column;
    color: var(--color-on-surface);
}

.headerSignUp{
    margin-top: 1rem;
    font-size: 1.5rem;
}

.pinSignUp {
    display: flex;
    gap: 1rem;
}

.verifyButtonSignUp {
    margin-top: 1rem;
}