@value max-small-tablet from "../../styles/css-values/breakpoints.module.css";

.footer {
  padding: 2rem 8rem 2rem 8rem;
  margin-top: 20vh;
  font-size: 1.1rem;
  color: var(--txt-main);
}

.footerContent {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
}

.footerContent * > h3 {
  text-decoration: underline;
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sections > a:hover {
  cursor: pointer;
  color: var(--color-logo);
}

.footerAppendix {
  width: 100%;
  border-top: 0.3px solid var(--txt-main);
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerAppendixIcons {
  font-size: 1.8rem;
}

.fbIcon:hover {
  color: #1D9BF0;
  cursor: pointer;
  scale: 10rem;
}

.twIcon:hover {
  color: #476FEA;
  cursor: pointer;
  scale: 10rem;
}

.liIcon:hover {
  color: #0077B7;
  cursor: pointer;
  scale: 10rem;
}

.copyright {
  border-top: 4px solid var(--primary);
  padding-top: 20px;
}

@media max-small-tablet {
  .footer {
    display: flex;
    flex-direction: column;
    padding: 1.6rem;
    padding-bottom: 8rem;
  }
}