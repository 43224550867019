.container :global(.h) {
  margin: 1.5em 0 1em;
}

.colors {
  display: grid;
  grid-template-columns: repeat(auto-fit, 312px);
  gap: 1.2rem 2.4rem;
}

.container summary :global(.h) {
  display: inline-block;
  cursor: pointer;
}

.color {
  padding: 1.2rem 24px;
  border-radius: 1.6rem;
}

.colorName {
  background: inherit;
  color: transparent;
  background-clip: text;
  filter: invert(1) grayscale(1) contrast(9);
}

.colorActions {
  display: flex;
  margin-top: 1.2rem;
  gap: 8px;
}
