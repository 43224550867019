.container {
  display: inline-flex;
  padding: 0.6rem;
}

.radar {
  padding: 0.2rem;
  border-radius: 2.4rem;
  height: 100%;
  display: flex;
}

.scrollContainer {
  border-radius: inherit;
  padding: 0 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 6.4rem;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.heading {
  composes: type-headline-small from global;
  display: flex;
  justify-content: space-between;
  overflow: visible;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}
