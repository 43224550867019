.focusRing {
  --height: 40px;
  display: inline-block;
  border-radius: calc(var(--height) / 2);
  position: relative;
}

.focusRing:global(.--isKeyboardFocused)::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 0.6rem);
  height: calc(100% + 0.6rem);
  transform: translate(-0.3rem, -0.3rem);
  border-radius: calc(var(--height) / 2 + 0.3rem);
  box-shadow: 0 0 0 2px var(--color-on-surface);
}

.focusRing:global(.--isKeyboardFocused) .button {
  box-shadow: none;
}

.button {
  background-color: var(--color-surface);
  height: var(--height);
  width: 100%;
  border-radius: inherit;
  padding: 0 24px;
  cursor: pointer;
  border-width: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* used for: hover, focus, pressed */
.button::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: var(--color-primary);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.button.hasLeftIcon {
  padding-left: 16px;
}

.button.hasRightIcon {
  padding-right: 16px;
}

.buttonContent {
  color: var(--color-primary);
  transition: color 0.15s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.buttonContent > *:not(.label) {
  font-size: 1.8rem;
}

/* margin left on every element (exept first) if multiple */
.buttonContent > *:not(:first-child) {
  margin-left: 8px;
}

/* disabled state */

.button:disabled {
  background-color: var(--color-disabled-container);
  cursor: auto;
}

.button:disabled .buttonContent {
  color: var(--color-disabled-content);
}

/* hovered state */

.button:not(:disabled):hover::before {
  opacity: var(--interaction-layer-hovered);
}

/* focused state */

.button:focus::before {
  opacity: var(--interaction-layer-focused);
}

/* pressed state */

.button:global(.--is-pressed)::before {
  opacity: var(--interaction-layer-pressed);
}
