.sentient-background {
  color: var(--color-on-background);
  background-color: var(--color-background);
}

.landing-page {
  font-family: 'Inter',sans-serif;
  font-size: 1.4rem;
  color: var(--txt-main);
  overflow: hidden;
}

.landing-header {
  display: flex;
  align-items: center;
  padding: 0 0rem;
  width: 100%;
}

.landing-logo {
  width: 15%;
  min-width: 150px;
}

.landing-logo img {
  width: 100%;
  vertical-align: middle;
}

.landing-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0rem;
}

.landing-nav:nth-child(2) {
  align-items: flex-start;
}

.explorer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0fr 1fr;
  gap: 2rem;
  width: 98vw;
  padding-left: 0.8rem;
}

.header {
  padding-left: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 13.6rem;
}

.landing-options {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: medium;
}
.landing-search {
  display: flex;
  align-items: center;
}

.landing-header__options {
  width: 35%;
  margin-left: 10px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-header__options > span {
  cursor: pointer;
}

.landing-lang {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-lang img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}

.landing-content {
  margin-top: 1.6rem;
  margin-bottom: 5rem;
  padding-left: 13.6rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content__main {
  width: 50%;
}

.content__main h1 {
  font-size: 4rem;
  font-weight: 400;
}

.content__main span {
  color: var(--land-txt-dark);
}

.content__form {
  /* background-color: green; */
  width: 50%;
  display: flex;
  justify-content: center;
  padding-top: 7.2rem;
}

.landing-currencies-imgs {
  margin: 1rem 0;
}

.landing-currencies-imgs img {
  margin-right: 1rem;
  height: 25px;
}

.burger-menu {
  display: none;
}

.markets-container {
  margin-right: 1rem;
}

.markets-headline {
  font-size: 600;
  font-weight: 500;
  margin-bottom: 3.2rem;
}

.markets {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.img {
  display: flex;
  height: 50px;
  margin-left: 1rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .markets {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1170px) {
  .markets {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1050px) {
  .landing-content {
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media screen and (max-width: 995px) {
  .burger-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .landing-header__options {
    justify-content: flex-end;
  }

  .landing-header__options > span,
  .landing-header__options > div {
    margin-right: 10px;
  }

  .landing-header__options .custom-button {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .landing-content {
    padding: 0 2rem;
  }

  .content__form {
    order: 1;
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .content__main {
    order: 2;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .landing-footer {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 599px) {
  .img {
    display: none;
    height: 0px;
    margin-left: 0;
    margin-top: 0;
  }

  .landing-nav {
    margin-left: -2vw;
  }

  .header {
    padding: 1.6rem 1.6rem 1.6rem 4rem;
  }

  .content__form {
    padding-top: 0.8rem;
  }

  .markets {
    grid-template-columns: 1fr 1fr;
  }
}


@media screen and (max-width: 420px) {

  .content__form {
    margin-top: 2rem;
  }
}
