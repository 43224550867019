.container {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 0 3.2rem;
  gap: 0.8rem 1.6rem;
  flex-wrap: wrap;
}

.errorContainer {
  min-width: 100%;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 28rem;
  gap: 0.8rem;
}

.actions {
  display: flex;
  gap: 0.8rem;
  justify-content: flex-end;
  margin-top: 1.6rem;
  min-width: 100%;
}

.exchangeRecord {
  position: unset;
  display: flex;
  flex-direction: column;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}