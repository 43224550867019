.tfa {
  font-family: 'Inter', sans-serif;
  padding: 1rem;
  max-width: 1224px;
  border-radius: 10px;
  color: var(--color-on-surface);
}

.tfa-enable,
.tfa-new-device {
  display: flex;
  gap: 2.9rem;
}

.tfa-enable {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.tfa__info {
  padding-right: 2rem;
  width: 320px;
}

.tfa__info h1 {
  font-size: 2.3rem;
}

.tfa__main {
  width: 60%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tfa__main img {
  width: 196px;
  height: 196px;
}

.tfa__main a:link,
.tfa__main a:visited {
  color: #418cd8;
  text-decoration: none;
}

.tfa__main a:hover {
  text-decoration: underline;
}

.enable__img {
  margin: 2rem 0;
}

.enable__form,
.disable__form {
  display: flex;
  flex-direction: column;
  width: 30rem;
  gap: 1rem;
}

.label {
  margin-top: 1rem;
}

.form-control {
  font-family: 'Inter', sans-serif;
  font-size: large;
  padding-left: 1rem;
  height: 4rem;
  border-radius: 10px;
  border: hidden;
  background-color: var(--color-background);
  color: var(--color-on-surface);
  width: 300px;
}
.form-control:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-on-surface);
}

.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control[type=number] {
  -moz-appearance: textfield;
}

.disable__form {
  margin-top: 1rem;
}

@media screen and (max-width: 996px) and (min-width: 811px) {
  .tfa {
    width: 80vw;
  }
}

@media screen and (max-width: 810px) {
  .tfa {
    height: auto;
    width: 75vw;
  }
  .tfa-enable,
  .tfa-new-device {
    flex-direction: column;
  }

  .tfa__info {
    width: 100%;
  }

  .tfa__main {
    width: 100%;
    padding-left: 0;
  }

  .tfa__main p {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 599px) {
  .tfa {
    width: 91vw;
  }
}